document.addEventListener("DOMContentLoaded", () => {
  let emailField = document.getElementById("email");
  let form = document.getElementById("sign-in-form");
  let signInButton = document.getElementById("sign-in-button");
  emailField.addEventListener("focusout", () => emailField.value = emailField.value.trim());

  form.onsubmit = () => {
    signInButton.disabled = true;
    signInButton.innerText = "Signing In...";
  }
});
